import { sortBy } from 'lodash';

import { isNotNullish, Nullable } from '@tager/web-core';
import { ThumbnailType } from '@tager/web-modules';

import {
  CatalogCar,
  CatalogDealer,
  CatalogFiltersData,
  CatalogStockBrandAndModelsResponse,
} from '@/services/catalog/typings';
import { SEOTemplate, SEOTemplatesTypes } from '@/typings/model';
import { OpenModalType, OptionType } from '@/typings/common';
import ApplicationFormModal from '@/components/modals/ApplicationFormModal';
import { StyledOverlay } from '@/components/modals/shared/StyledOverlay';
import defaultStockCar from '@/assets/images/cars-stock/defaultStockCar.png';
import { getCityFromCookie } from '@/utils/cookies';
import { getSeoTemplateText } from '@/utils/seo';
import { handleSubmitCarCatalogForm } from '@/utils/forms';

export const getCatalogCarButton = (
  openModal: OpenModalType,
  car: CatalogCar,
  buttonLink: string,
  event?: string
): {
  label: string;
  url?: string;
  onClick?: () => void;
} => {
  if (car.stock.quantity || car.stock.preorderQuantity) {
    return {
      label: car.stock.quantity
        ? `Авто в наличии (${car.stock.quantity})`
        : `Под заказ (${car.stock.preorderQuantity})`,
      url: buttonLink,
    };
  } else {
    return {
      label: 'Хочу похожее авто',
      onClick: () =>
        openModal(
          ApplicationFormModal,
          {
            title: 'Хочу похожее авто',
            fieldsConfig: {
              dealers: !car.isPartnerCar
                ? getDealersOptions(car.dealers)
                : undefined,
            },
            onSubmit: (values) =>
              handleSubmitCarCatalogForm(
                {
                  ...values,
                  model: car.name,
                  dealer:
                    values.dealer && !car.isPartnerCar
                      ? Number(values.dealer.value)
                      : undefined,
                },
                {
                  type: event,
                  params: { car: car.name },
                }
              ),
          },
          { components: { Overlay: StyledOverlay }, scrollLockDisabled: true }
        ),
    };
  }
};

export const getCatalogCarLink = (
  car: CatalogCar
): { label: string; url: string } => {
  return { label: 'О модели', url: car.url };
};

export const getCatalogCarImages = (
  car: CatalogCar
): { color?: OptionType; items: ThumbnailType[] }[] => {
  return car.colors && car.colors.length > 0
    ? car.colors.map(({ hex, name, images }) => ({
        color: { label: name, value: '#' + hex },
        items:
          images && images.length > 0
            ? images
                .slice(0, 5)
                .map((image) => image.desktop ?? { url: defaultStockCar })
            : [{ url: defaultStockCar }],
      }))
    : car.images && car.images.length > 0
    ? [
        {
          items: car.images
            .slice(0, 5)
            .map((image) => image.desktop ?? { url: defaultStockCar }),
        },
      ]
    : [{ items: [{ url: defaultStockCar }] }];
};

export function parseUrl(string: string) {
  if (string.substring(0, 7) === 'filter-') {
    let arr: string[] = [];
    string = string.substring(7);

    if (string.substring(0, 6) === 'brand-') {
      let newString = string.substring(6);

      if (newString.indexOf('body') !== -1) {
        arr = [
          ...arr,
          ...newString
            .substring(0, newString.indexOf('body') - 2)
            .split('--and--'),
        ];
      } else if (newString.indexOf('color') !== -1) {
        arr = [
          ...arr,
          ...newString
            .substring(0, newString.indexOf('color') - 2)
            .split('--and--'),
        ];
      } else {
        arr = [...arr, ...newString.split('--and--')];
      }
    }

    if (string.indexOf('body') !== -1) {
      let newString = string.substring(string.indexOf('body') + 5);
      if (newString.indexOf('color') !== -1) {
        arr = [
          ...arr,
          ...newString
            .substring(0, newString.indexOf('color') - 2)
            .split('--and--'),
        ];
      } else {
        arr = [...arr, ...newString.split('--and--')];
      }
    }

    if (string.indexOf('color') !== -1) {
      let newString = string.substring(string.indexOf('color') + 6);
      arr = [...arr, ...newString.split('--and--')];
    }

    if (string.indexOf('fuel') !== -1) {
      let newString = string.substring(string.indexOf('fuel') + 5);
      arr = [...arr, ...newString.split('--and--')];
    }

    return arr;
  } else {
    return [string];
  }
}

export function convertedActiveCheckboxes(
  activeCheckboxes: string[],
  allCheckboxes: CatalogFiltersData
) {
  let arr: { name: string; id: number; hex?: string; alias: string }[] = [
    ...allCheckboxes.brands.filter((item) =>
      activeCheckboxes.includes(item.alias)
    ),
    ...allCheckboxes.bodies.filter((item) =>
      activeCheckboxes.includes(item.alias)
    ),
    ...allCheckboxes.colors.filter((item) =>
      activeCheckboxes.includes(item.alias)
    ),
    ...allCheckboxes.fuelTypes.filter((item) =>
      activeCheckboxes.includes(item.alias)
    ),
  ];
  return arr.map((item) => {
    return { value: item.name, label: item.name };
  });
}

export function getCityUrl(): string {
  const city = getCityFromCookie();

  return city ? `/${city.value}/` : '';
}

export function getReplaceUrl(newUrl: string) {
  return newUrl ? newUrl + getCityUrl() : `/catalog${getCityUrl()}`;
}

export function getCatalogSEOTemplate(
  activeFiltersCheckbox: OptionType[],
  filters: Nullable<CatalogFiltersData>,
  city?: { default?: string; withCase?: string },
  seoData?: Nullable<SEOTemplate>
): { template: SEOTemplatesTypes; data: Nullable<SEOTemplate> } {
  const defaultH1 = 'Модельный ряд';
  const brandsNames = filters?.brands.map((brand) => brand.name) ?? [];

  if (filters && activeFiltersCheckbox.length === 1) {
    const bodiesNames = filters.bodies.map((body) => body.name);
    const fuelTypesNames = filters.fuelTypes.map((fuelType) => fuelType.name);
    const activeFilter = activeFiltersCheckbox[0].label;

    if (brandsNames.includes(activeFilter)) {
      const convertedSeoData = seoData
        ? {
            ...seoData,
            title:
              seoData.title
                ?.replaceAll('{{brand}}', activeFilter)
                ?.replaceAll('{{city}}', city?.default ?? '')
                ?.replaceAll('{{cityWithCase}}', city?.withCase ?? '') ?? '',
            description:
              seoData.description
                ?.replaceAll('{{brand}}', activeFilter)
                ?.replaceAll('{{city}}', city?.default ?? '')
                ?.replaceAll('{{cityWithCase}}', city?.withCase ?? '') ?? '',
            h1: getSeoTemplateText(
              seoData.h1
                ?.replaceAll('{{brand}}', activeFilter)
                ?.replaceAll('{{city}}', city?.default ?? '')
                ?.replaceAll('{{cityWithCase}}', city?.withCase ?? '') ?? '',
              defaultH1
            ),
          }
        : null;

      if (city && (city.default || city.withCase)) {
        return { template: 'catalog_brand_city', data: convertedSeoData };
      }

      return { template: 'catalog_brand', data: convertedSeoData };
    } else if (bodiesNames.includes(activeFilter)) {
      const pluralName =
        filters.bodies.find((body) => body.name === activeFilter)?.pluralName ??
        '';

      const convertedSeoData = seoData
        ? {
            ...seoData,
            title:
              seoData.title
                ?.replaceAll('{{name}}', activeFilter)
                ?.replaceAll('{{name|lowercase}}', activeFilter.toLowerCase())
                ?.replaceAll('{{plural_name}}', pluralName)
                ?.replaceAll(
                  '{{plural_name|lowercase}}',
                  pluralName.toLowerCase()
                )
                ?.replaceAll('{{city}}', city?.default ?? '')
                ?.replaceAll('{{cityWithCase}}', city?.withCase ?? '') ?? '',
            description:
              seoData.description
                ?.replaceAll('{{name}}', activeFilter)
                ?.replaceAll('{{name|lowercase}}', activeFilter.toLowerCase())
                ?.replaceAll('{{plural_name}}', pluralName)
                ?.replaceAll(
                  '{{plural_name|lowercase}}',
                  pluralName.toLowerCase()
                )
                ?.replaceAll('{{city}}', city?.default ?? '')
                ?.replaceAll('{{cityWithCase}}', city?.withCase ?? '') ?? '',
            h1: getSeoTemplateText(
              seoData.h1
                ?.replaceAll('{{name}}', activeFilter)
                ?.replaceAll('{{name|lowercase}}', activeFilter.toLowerCase())
                ?.replaceAll('{{plural_name}}', pluralName)
                ?.replaceAll(
                  '{{plural_name|lowercase}}',
                  pluralName.toLowerCase()
                )
                ?.replaceAll('{{city}}', city?.default ?? '')
                ?.replaceAll('{{cityWithCase}}', city?.withCase ?? '') ?? '',
              defaultH1
            ),
          }
        : null;

      if (city && (city.default || city.withCase)) {
        return { template: 'catalog_filter_body_city', data: convertedSeoData };
      }

      return { template: 'catalog_filter_body', data: convertedSeoData };
    } else if (fuelTypesNames.includes(activeFilter)) {
      const foundFuelType = filters.fuelTypes.find(
        (fuelType) => fuelType.name === activeFilter
      );

      const convertedSeoData =
        seoData && foundFuelType
          ? {
              ...seoData,
              title:
                seoData.title
                  ?.replaceAll('{{singular_name}}', foundFuelType.singularName)
                  ?.replaceAll(
                    '{{singular_name|lowercase}}',
                    foundFuelType.singularName.toLowerCase()
                  )
                  ?.replaceAll('{{plural_name}}', foundFuelType.pluralShortName)
                  ?.replaceAll(
                    '{{plural_name|lowercase}}',
                    foundFuelType.pluralShortName.toLowerCase()
                  )
                  ?.replaceAll(
                    '{{plural_full_name}}',
                    foundFuelType.pluralFullName
                  )
                  ?.replaceAll(
                    '{{plural_full_name|lowercase}}',
                    foundFuelType.pluralFullName.toLowerCase()
                  ) ?? '',
              description:
                seoData.description
                  ?.replaceAll('{{singular_name}}', foundFuelType.singularName)
                  ?.replaceAll(
                    '{{singular_name|lowercase}}',
                    foundFuelType.singularName.toLowerCase()
                  )
                  ?.replaceAll('{{plural_name}}', foundFuelType.pluralShortName)
                  ?.replaceAll(
                    '{{plural_name|lowercase}}',
                    foundFuelType.pluralShortName.toLowerCase()
                  )
                  ?.replaceAll(
                    '{{plural_full_name}}',
                    foundFuelType.pluralFullName
                  )
                  ?.replaceAll(
                    '{{plural_full_name|lowercase}}',
                    foundFuelType.pluralFullName.toLowerCase()
                  ) ?? '',
              h1: getSeoTemplateText(
                seoData.h1
                  ?.replaceAll('{{singular_name}}', foundFuelType.singularName)
                  ?.replaceAll(
                    '{{singular_name|lowercase}}',
                    foundFuelType.singularName.toLowerCase()
                  )
                  ?.replaceAll('{{plural_name}}', foundFuelType.pluralShortName)
                  ?.replaceAll(
                    '{{plural_name|lowercase}}',
                    foundFuelType.pluralShortName.toLowerCase()
                  )
                  ?.replaceAll(
                    '{{plural_full_name}}',
                    foundFuelType.pluralFullName
                  )
                  ?.replaceAll(
                    '{{plural_full_name|lowercase}}',
                    foundFuelType.pluralFullName.toLowerCase()
                  ) ?? '',
                defaultH1
              ),
            }
          : null;

      return { template: 'catalog_filter_fuel', data: convertedSeoData };
    }
  } else if (filters && activeFiltersCheckbox.length > 1) {
    if (
      activeFiltersCheckbox.every((item) => brandsNames.includes(item.label))
    ) {
      return {
        template: 'catalog_brands',
        data: seoData
          ? {
              ...seoData,
              h1: getSeoTemplateText(seoData.h1 ?? '', defaultH1),
            }
          : null,
      };
    }
  }

  const convertedSeoData = seoData
    ? {
        ...seoData,
        title:
          seoData.title
            ?.replaceAll('{{city}}', city?.default ?? '')
            ?.replaceAll('{{cityWithCase}}', city?.withCase ?? '') ?? '',
        description:
          seoData.description
            ?.replaceAll('{{city}}', city?.default ?? '')
            ?.replaceAll('{{cityWithCase}}', city?.withCase ?? '') ?? '',
        h1: getSeoTemplateText(
          seoData.h1
            ?.replaceAll('{{city}}', city?.default ?? '')
            ?.replaceAll('{{cityWithCase}}', city?.withCase ?? '') ?? '',
          defaultH1
        ),
      }
    : null;

  if (city && (city.default || city.withCase)) {
    return { template: 'catalog_city', data: convertedSeoData };
  }

  return { template: 'catalog', data: convertedSeoData };
}

export const getCatalogCarsMinAndMaxPrices = (cars: CatalogCar[]) => {
  if (!cars || cars.length === 0) {
    return { minPrice: 0, maxPrice: 0 };
  }

  const prices = cars.map(({ stock }) => stock.minPrice).filter(isNotNullish);

  if (prices.length < 1) {
    return { minPrice: 0, maxPrice: 0 };
  }

  return { minPrice: Math.min(...prices), maxPrice: Math.max(...prices) };
};

export const getCatalogCarsByIds = (
  cars: CatalogCar[],
  ids: string[]
): CatalogCar[] => {
  return sortBy(
    cars.filter((car) => ids.includes(String(car.id))),
    (car) => ids.indexOf(String(car.id))
  );
};

export const getCatalogStockBrandDealerIds = (
  brands: CatalogStockBrandAndModelsResponse[],
  searchId: number
): number[] => {
  const foundBrand = brands.find((brand) => brand.id === searchId);

  return foundBrand
    ? foundBrand.dealers && foundBrand.dealers.length !== 0
      ? foundBrand.dealers.map((brand) => brand.id)
      : []
    : [];
};

export const getDealersOptions = (dealers: CatalogDealer[]): OptionType[] => {
  return dealers && dealers.length !== 0
    ? dealers.map((dealer) => ({
        label: `${dealer.address}, ${dealer.name} `,
        value: String(dealer.id),
      }))
    : [];
};
