import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import Button, { ButtonNextLink } from '@/components/Button';

import { ButtonsPanelProps } from './types';

export const ButtonsPanel = ({ buttons }: ButtonsPanelProps) => {
  if (!buttons || buttons.length === 0) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <Buttons>
          {buttons.map(({ title, link, isNewTab, onClick }, index) =>
            link ? (
              <StyledButtonLink
                key={index}
                variant="contained"
                to={link}
                target={isNewTab ? '_blank' : '_self'}
              >
                {title}
              </StyledButtonLink>
            ) : (
              <StyledButton key={index} variant="contained" onClick={onClick}>
                {title}
              </StyledButton>
            )
          )}
        </Buttons>
      </ContentContainer>
    </Component>
  );
};

const Component = styled.div`
  position: sticky;
  bottom: 0;
  z-index: 100;
  padding: 20px 0;
  background: ${(props) => props.theme.gray_1};
  box-shadow: ${(props) => props.theme.blackShadow400};
`;

const ContentContainer = styled.div`
  max-width: 1280px;
  padding: 0 40px;
  margin: 0 auto;

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;

  ${media.tablet(css`
    flex-direction: column;
    align-items: center;
  `)}
`;

const ButtonStyles = css`
  max-width: 280px;

  &:not(:first-child) {
    margin-left: 10px;

    ${media.tablet(css`
      margin: 10px 0 0;
    `)}
  }
`;

const StyledButtonLink = styled(ButtonNextLink)`
  ${ButtonStyles}
`;

const StyledButton = styled(Button)`
  ${ButtonStyles}
`;
